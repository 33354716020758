// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              locationId: field.required("locationId", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              mainImageUrl: field.required("mainImageUrl", Json_Decode$JsonCombinators.string),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              providerAgreement: field.required("providerAgreement", Json_Decode$JsonCombinators.bool),
              sponsoredGlobal: field.required("sponsoredGlobal", Json_Decode$JsonCombinators.bool),
              sponsoredCountry: field.required("sponsoredCountry", Json_Decode$JsonCombinators.bool),
              sponsoredState: field.required("sponsoredState", Json_Decode$JsonCombinators.bool),
              sponsoredCity: field.required("sponsoredCity", Json_Decode$JsonCombinators.bool),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var PreloadedSearchLocation = {
  decoder: decoder,
  fromJson: fromJson
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string)
            };
    });

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providers: field.required("providers", Json_Decode$JsonCombinators.array(decoder$1))
            };
    });

function query(queryString) {
  return Belt_Option.getWithDefault(Belt_Option.map(queryString, (function (x) {
                    return "query=" + encodeURIComponent(x);
                  })), "");
}

var Index = {
  decoder: decoder$2,
  query: query
};

var Provider = {
  decoder: decoder$1,
  Index: Index
};

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

var Country = {
  decoder: decoder$3
};

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              countrySlug: field.required("countrySlug", Json_Decode$JsonCombinators.string)
            };
    });

var State = {
  decoder: decoder$4
};

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              countryName: field.required("countryName", Json_Decode$JsonCombinators.string),
              countrySlug: field.required("countrySlug", Json_Decode$JsonCombinators.string),
              stateName: field.required("stateName", Json_Decode$JsonCombinators.string),
              stateSlug: field.required("stateSlug", Json_Decode$JsonCombinators.string)
            };
    });

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              cities: field.required("cities", Json_Decode$JsonCombinators.array(decoder$5))
            };
    });

function query$1(queryString) {
  return Belt_Option.getWithDefault(Belt_Option.map(queryString, (function (x) {
                    return "query=" + encodeURIComponent(x);
                  })), "");
}

var Index$1 = {
  decoder: decoder$6,
  query: query$1
};

var City = {
  decoder: decoder$5,
  Index: Index$1
};

var decoder$7 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              preloadedSearchLocations: field.required("preloadedSearchLocations", Json_Decode$JsonCombinators.array(decoder))
            };
    });

var $$Map = {
  decoder: decoder$7
};

var decoder$8 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              preloadedSearchLocations: field.required("preloadedSearchLocations", Json_Decode$JsonCombinators.array(decoder)),
              totalPreloadedSearchLocations: field.required("totalPreloadedSearchLocations", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int),
              providers: field.required("providers", Json_Decode$JsonCombinators.array(decoder$1)),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.$$int),
              countries: field.required("countries", Json_Decode$JsonCombinators.array(decoder$3)),
              totalCountries: field.required("totalCountries", Json_Decode$JsonCombinators.$$int),
              states: field.required("states", Json_Decode$JsonCombinators.array(decoder$4)),
              totalStates: field.required("totalStates", Json_Decode$JsonCombinators.$$int),
              cities: field.required("cities", Json_Decode$JsonCombinators.array(decoder$5)),
              totalCities: field.required("totalCities", Json_Decode$JsonCombinators.$$int),
              userRole: field.required("userRole", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$8));
}

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

function query$2(queryString) {
  return Belt_Option.getWithDefault(Belt_Option.map(queryString, (function (x) {
                    return "query=" + encodeURIComponent(x);
                  })), "");
}

function page(pageNum) {
  return "&page=" + String(pageNum);
}

function providers(ids) {
  if (ids !== undefined && ids.length !== 0) {
    return "&" + (function (__x) {
                return Js_array.joinWith("&", __x);
              })(Belt_Array.map(ids, (function (id) {
                      return "provider_ids[]=" + ID.toString(id);
                    })));
  } else {
    return "";
  }
}

function sortBy(sortBy$1) {
  var variant = sortBy$1.NAME;
  if (variant === "StateName") {
    return "&sort_by=preloaded_search_locations.state_name&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "CountryName") {
    return "&sort_by=preloaded_search_locations.country_name&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "Sponsored") {
    return "&sort_by=preloaded_search_locations.sponsored_city&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "ProviderName") {
    return "&sort_by=preloaded_search_locations.provider_name&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "Popular") {
    return "&sort_by=locations.ahoy_events_count&sort_direction=" + toString(sortBy$1.VAL);
  } else {
    return "&sort_by=preloaded_search_locations.city_name&sort_direction=" + toString(sortBy$1.VAL);
  }
}

function cityId(id) {
  if (id !== undefined) {
    return "&city_id=" + ID.toString(Caml_option.valFromOption(id));
  } else {
    return "";
  }
}

function radius(radius$1) {
  if (radius$1 !== undefined) {
    return "&radius=" + String(radius$1);
  } else {
    return "";
  }
}

var Index$2 = {
  PreloadedSearchLocation: PreloadedSearchLocation,
  Provider: Provider,
  Country: Country,
  State: State,
  City: City,
  $$Map: $$Map,
  decoder: decoder$8,
  fromJson: fromJson$1,
  SortDirection: SortDirection,
  SortBy: SortBy,
  query: query$2,
  page: page,
  providers: providers,
  sortBy: sortBy,
  cityId: cityId,
  radius: radius
};

export {
  Index$2 as Index,
}
/* decoder Not a pure module */
